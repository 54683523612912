import React from 'react'
import tw from 'twin.macro'
import PropTypes from 'prop-types'

const ContainedButton = ({
  size,
  color = 'primary',
  children,
  styles,
  type,
  onClick,
  ...props
}) => {
  return (
    <button
      size={size}
      color={color}
      onClick={onClick}
      type={type}
      css={[
        tw`py-2 px-4 bg-accent-500 border-2 border-accent-700 hover:bg-accent-700 text-white rounded-lg text-center focus:outline-none`,
        size === 'xl' && tw`py-4 px-8 text-xl`,
        size === 'sm' && tw`py-2 px-4`,
        props.disabled && tw`opacity-75 cursor-not-allowed hover:bg-accent-500`,
        color === 'secondary' &&
          tw`bg-secondary-700 hover:bg-secondary-900 border-secondary-900`,
        ...(styles ?? []),
      ]}
      {...props}
    >
      {children}
    </button>
  )
}

ContainedButton.propTypes = {
  size: PropTypes.oneOf(['xl', 'base', 'sm']),
  color: PropTypes.oneOf(['primary', 'secondary']),
}

export default ContainedButton
