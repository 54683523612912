import React from 'react'
import PropTypes from 'prop-types'

import SEO from 'components/seo'
import Layout from './layout'

const PreliminaryApplicationLayout = ({
  children,
  step,
  stepNumber,
  location,
}) => {
  return (
    <Layout title="Preliminary Application">
      <SEO
        title={
          step ? `${step} - Preliminary Application` : 'Preliminary Application'
        }
        path={location.pathname}
      />

      <div className="my-10 px-4">
        {stepNumber && <p className="text-center mb-4">Step {stepNumber}</p>}

        <h2 className="md:text-center mb-4">{step}</h2>

        {children}
      </div>
    </Layout>
  )
}

PreliminaryApplicationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  step: PropTypes.string,
}

export default PreliminaryApplicationLayout
