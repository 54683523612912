import React, { useRef } from 'react'
import tw, { css } from 'twin.macro'

import ContainedButton from 'components/buttons/contained-button'
import Input from 'components/input'

export default ({
  formData,
  errors,
  onAddEducation,
  onRemoveEducation,
  onChange,
  onSubmit,
  onBack,
}) => {
  const containerEl = useRef(null)
  const educations =
    formData.educations && formData.educations.length >= 1
      ? formData.educations
      : []

  const handleSubmit = event =>
    onSubmit(containerEl.current?.children ?? [])(event)

  return (
    <div>
      <div ref={containerEl}>
        {educations.map((education, index) => {
          const eduErrors =
            errors && errors.educations ? errors.educations[index] ?? {} : {}

          return (
            <form
              onChange={onChange('educations')(index)}
              key={index}
              className="mb-4"
            >
              <div
                className="md:flex flex-row flex-wrap"
                css={[
                  css`
                    > * {
                      ${tw`md:mr-4`}
                    }
                  `,
                ]}
              >
                <Input
                  label="School"
                  name="schoolName"
                  type="text"
                  error={eduErrors.schoolName}
                  defaultValue={education.schoolName}
                  required
                />

                <Input
                  label="Location"
                  name="location"
                  type="text"
                  error={eduErrors.location}
                  defaultValue={education.location}
                  required
                />

                <Input
                  label="Level of Education"
                  name="educationLevel"
                  type="text"
                  error={eduErrors.educationLevel}
                  defaultValue={education.educationLevel}
                  required
                />

                <Input
                  label="Major"
                  name="major"
                  type="text"
                  error={eduErrors.major}
                  defaultValue={education.major}
                />

                <Input
                  label="Graduation Date"
                  name="graduationDate"
                  type="month"
                  error={eduErrors.graduationDate}
                  defaultValue={education.graduationDate}
                  required
                />
              </div>

              {educations.length > 1 && (
                <ContainedButton
                  onClick={onRemoveEducation(index)}
                  type="button"
                  styles={[tw`bg-red-500 hover:bg-red-700 border-red-700`]}
                >
                  Remove
                </ContainedButton>
              )}

              {educations.length > 1 && index !== educations.length - 1 && (
                <div className="border-gray-200 border my-4"></div>
              )}

              <ContainedButton type="submit" className="hidden">
                Submit
              </ContainedButton>
            </form>
          )
        })}
      </div>

      <div className="border-gray-400 border my-4"></div>

      <ContainedButton
        onClick={onAddEducation}
        styles={[tw`bg-blue-500 hover:bg-blue-700 border-blue-700 mb-4`]}
      >
        Add More
      </ContainedButton>

      <div className="flex flex-row justify-between">
        <ContainedButton onClick={onBack}>Back</ContainedButton>
        <ContainedButton onClick={handleSubmit}>Continue</ContainedButton>
      </div>
    </div>
  )
}
