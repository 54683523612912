import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

import ApplicationLayout from 'components/layouts/preliminary-application-layout'
import { usePreliminaryApplication } from 'contexts/preliminary-application-context'
import EducationalForm from 'components/pages/preliminary-application/educational-background-form'

export default ({ location }) => {
  const application = usePreliminaryApplication()
  const [formData, setFormData] = useState(undefined)
  const [errors, setErrors] = useState(undefined)

  useEffect(() => {
    if (application.initialized) {
      application.setStep('educationBackground')
      setFormData({ ...application.educationalBackground.fields })
      setErrors({ ...application.educationalBackground.errors })

      if (!application.educationalBackground.fields.educations) {
        // TODO:
        setFormData({ ...application.educationalBackground.fields })
      }
    }
  }, [application.initialized])

  const handleFormChange = type => index => event => {
    const { name, value } = event.target

    if (type === 'educations') {
      setFormData({
        ...formData,
        educations: [
          ...formData.educations.slice(0, index),
          {
            ...formData.educations[index],
            [name]: value,
          },
          ...formData.educations.slice(index + 1),
        ],
      })
    }
  }

  const handleFormSubmit = formsEl => async event => {
    event.preventDefault()

    // Validate forms
    let i = -1
    while (++i < formsEl.length) {
      const form = formsEl[i]
      const result = form.reportValidity()
      if (!result) return
    }

    try {
      await application.update('educationalBackground', formData)

      navigate('/preliminary-application/general-information')
    } catch (error) {
      // TODO:
      console.error(error)
      window.alert(error.message)
    }
  }

  const handleGoBack = event => {
    event.preventDefault()
    navigate('/preliminary-application/business-experience')
  }

  const handleAddEducation = event => {
    console.log('add education ')
    event.preventDefault()

    setFormData({
      ...formData,
      educations: [...(formData.educations ?? []), {}],
    })
  }

  const handleRemoveEducation = index => event => {
    console.log('remove education ', index)
    event.preventDefault()

    const educations = formData.educations ?? []

    console.log('educations', educations)

    setFormData({
      ...formData,
      educations: [
        ...educations.slice(0, index),
        ...educations.slice(index + 1),
      ],
    })
  }

  if (!application.initialized || !formData) {
    // TODO: beautify Loading
    return <div>Loading...</div>
  }

  console.log('formData', formData)

  return (
    <ApplicationLayout
      step="Educational Background"
      stepNumber={3}
      location={location}
    >
      <EducationalForm
        formData={formData}
        errors={errors}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        onAddEducation={handleAddEducation}
        onRemoveEducation={handleRemoveEducation}
        onBack={handleGoBack}
      />
    </ApplicationLayout>
  )
}
