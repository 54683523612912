import React from 'react'
import tw from 'twin.macro'

const Input = ({
  label,
  type,
  name,
  placeholder,
  error,
  styles,
  required,
  defaultValue,
  ...props
}) => {
  return (
    <div
      css={[
        tw`mb-3 focus-within:text-accent-500 text-gray-800`,
        ...(styles ?? []),
      ]}
    >
      {label && (
        <label htmlFor={name} className="block ml-3 mb-1 font-bold">
          {label}
          {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <input
        name={name}
        type={type}
        className="block appearance-none w-full py-2 px-3 border-2 border-gray-400 focus:border-accent-400 text-gray-700 focus:outline-none leading-tight rounded-md"
        placeholder={placeholder}
        required={required}
        defaultValue={defaultValue}
        {...props}
      />
      {error && <p className="ml-3 text-red-900">{error}</p>}
    </div>
  )
}

export const InputGroup = ({
  label,
  name,
  error,
  children,
  styles,
  required,
}) => {
  return (
    <div
      css={[
        tw`mb-3 focus-within:text-accent-500 text-gray-800`,
        ...(styles ?? []),
      ]}
    >
      {label && (
        <label htmlFor={name} className="block ml-3 mb-1 font-bold">
          {label}
          {required && <span className="text-red-600">*</span>}
        </label>
      )}
      {children}
      {error && <p className="ml-3 text-red-900">{error}</p>}
    </div>
  )
}

export default Input
